import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { configureDayjs } from "./configureDayjs.ts";
import { configureSentry } from "./Sentry/configureSentry.ts";
configureSentry();
configureDayjs();
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Auth0Provider
      onRedirectCallback={(appState) => {
        window.location.href = appState?.returnTo || window.location.pathname;
      }}
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        scope: "admin email profile",
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
);
