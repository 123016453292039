import { Link } from "react-router-dom";
import { ClassRoomSlotCardsProp } from "./ClassRoomSlotCardsProp";
import { useControlCalendarCardModal } from "./useControlCalendarModal";
import { dateFormatter } from "./FranchiseeMetods";
import SvgTime from "../UI/Svgs/SvgTime";
import { SvgParticipant } from "../UI/Svgs/SvgParticipant";
import { CalendarDaysIcon, LinkIcon } from "@heroicons/react/24/outline";
import { SvgShield } from "../UI/Svgs/SvgShield";
import { SvgBook } from "../UI/Svgs/SvgBook";
import { SvgMeet } from "../UI/Svgs/SvgMeet";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

export function SpeakingClassSlotCard({ slot }: ClassRoomSlotCardsProp) {
  const { showModal, onClose, onOpen } = useControlCalendarCardModal(slot.id);

  const date = new Date(slot.startedAtUtc);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formattedDate = date.toLocaleDateString("en-EN", options);

  return (
    <>
      <div
        onClick={onOpen}
        className="relative rounded-xl md:w-full text-sm w-[95%] hover:scale-105 transition-all ease-in-out flex justify-self-center border-t-4 border-t-norma-purple bg-white"
      >
        <div
          className="w-full flex md:gap-1 gap-2 flex-col items-start tracking-wide rounded-xl text-center shadow-lg py-2 pl-2"
          role="button"
          tabIndex={0}
        >
          <p className="font-bold tracking-wider text-sm">{slot.topic.name}</p>
          <div className="flex justify-center items-center gap-2">
            <SvgTime className="w-4 h-4" />
            <p className="text-center">{dateFormatter(slot.startedAtUtc)}</p>
          </div>

          <div className="flex justify-center items-center gap-2">
            <SvgParticipant className="w-4 h-4" />
            <p className="text-sm">{slot.participantsCount} / 6</p>
          </div>
        </div>
      </div>
      <Dialog
        open={showModal}
        onClose={onClose}
        className="fixed inset-0 overflow-y-auto data-[closed]:opacity-0"
        transition
        unmount={false}
      >
        <DialogBackdrop className="fixed inset-0 bg-gray-500/40" transition />
        <div className="flex items-center justify-center min-h-screen">
          <DialogPanel
            transition
            className="transition-all duration-300 ease-in-out data-[closed]:opacity-0 data-[closed]:scale-95 py-6 z-10 px-10 w-[80%] md:w-[35%] md:h-[40%] h-[45%] shadow-lg bg-white flex flex-col rounded-2xl border-t-8 border-norma-purple"
          >
            <div className="w-full h-full flex flex-col">
              <p className="py-4 text-lg pl-5 font-semibold">
                {slot.topic.name}
              </p>
              <div className="flex flex-col w-full h-full gap-1">
                <div className="flex items-center gap-2">
                  <CalendarDaysIcon className="w-5 h-5" />
                  <p>{formattedDate}</p>
                </div>
                <div className="flex items-center gap-2">
                  <SvgShield />
                  <p>{slot.topic.level}</p>
                </div>
                <div className="flex items-center gap-2">
                  <SvgParticipant className="w-5 h-5" />
                  <p>{slot.participantsCount} / 6</p>
                </div>
                <div className="flex items-center gap-2">
                  <SvgBook />
                  <Link
                    to={slot.topic.pdfUrl}
                    className="underline underline-offset-2"
                  >
                    View PDF
                  </Link>
                </div>
                <div className="flex items-center gap-2">
                  <LinkIcon className="w-5 h-5" />
                  <Link
                    to={slot.feedbackUri}
                    className="underline underline-offset-2"
                  >
                    Feedback
                  </Link>
                  /
                  <Link
                    to={slot.feedbackReportUri}
                    className="underline underline-offset-2"
                  >
                    Report
                  </Link>
                </div>

                <div className="flex items-center gap-2">
                  <SvgMeet />
                  <Link
                    to={slot.meetingUri}
                    className="underline underline-offset-2"
                  >
                    Link to Google Meet
                  </Link>
                </div>
              </div>
              <div className="w-full flex justify-center">
                <button
                  className="text-center font-semibold underline text-gray-600 hover:text-black"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
