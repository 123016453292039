import { useCallback } from "react";
import { decode, encode } from "js-base64";
import { useSearchParams } from "react-router-dom";

export function useHandleRedirectToOldUrl() {
  const redirectParamName = "r";

  const [searchParams] = useSearchParams();
  const encodedRedirectUrl = searchParams.get(redirectParamName);
  const redirect = encodedRedirectUrl
    ? decode(encodedRedirectUrl)
    : "/my-calendar";

  const getUrlWithRedirect = useCallback((url: URL) => {
    const u = new URL(url);
    u.searchParams.set(redirectParamName, encode(window.location.href));
    return u;
  }, []);

  return {
    getUrlWithRedirect,
    redirect,
  };
}
