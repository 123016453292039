import { graphql } from "../gql";

export const ClassroomV2GraphqlFragment = graphql(`
  fragment ClassRoomV2 on ClassRoomV2 {
    id
    startedAtUtc
    endedAtUtc
    participantsCount
    speakingLevel
    topic {
      id
      level
      name
      pdfUrl
    }
    type
    meetingUri
    capacity
    feedbackUri
    progressCheckTopic {
      level
      pdfUri
    }
    feedbackReportUri
  }
`);
