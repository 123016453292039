import { useQuery } from "@apollo/client";
import { getFragmentData, graphql } from "../gql";

const GetCurrentLoggedInTeacher = graphql(`
  query getCurrentLoggedInTeacher {
    getLoggedTeacher {
      ...Teacher
    }
  }
`);

const TeacherFragment = graphql(`
  fragment Teacher on Franchisee {
    email
    familyName
    givenName
    id
    phoneNumber {
      countryCode
      number
    }
    shiftBaseId
  }
`);

export function useLoggedTeacher(skip: boolean) {
  const { data, loading, refetch, error } = useQuery(
    GetCurrentLoggedInTeacher,
    {
      skip,
    },
  );
  if (error) {
    console.error("Error fetching logged-in teacher", error);
  }

  return {
    teacher: getFragmentData(TeacherFragment, data?.getLoggedTeacher),
    loading,
    refetch,
  };
}
