import { useAuth0 } from "@auth0/auth0-react";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useLoggedTeacher } from "./useLoggedTeacher";
import * as Sentry from "@sentry/browser";
import { FullPageLoading } from "../UI/Loading/FullPageLoading";
import { AuthCtx } from "./AuthCtx";

type Props = PropsWithChildren;

export function AuthCtxProvider({ children }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const { user: authTeacher, isLoading, logout } = useAuth0();
  const {
    teacher,
    loading: loadingTeacher,
    refetch: refetchMe,
  } = useLoggedTeacher(!authTeacher);

  useEffect(() => {
    if (!teacher) Sentry.setUser(null);
    else
      Sentry.setUser({
        id: teacher.id,
        email: teacher.email,
        username: `${teacher.givenName} ${teacher.familyName}`,
      });
  }, [teacher]);

  const refetch = useCallback(async () => {
    await refetchMe();
  }, [refetchMe]);

  useEffect(() => {
    if (isLoading) return;
    if (!authTeacher) {
      setLoading(false);
      return;
    }
    if (loadingTeacher) return;
    if (!teacher) {
      setLoading(false);
      return;
    }
    setLoading(false);
  }, [authTeacher, isLoading, loadingTeacher, teacher]);

  const signOut = useCallback(async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  if (loading) return <FullPageLoading />;

  return (
    <AuthCtx.Provider
      value={{
        authTeacher,
        signOut,
        refetch,
        teacher,
      }}
    >
      {children ?? null}
    </AuthCtx.Provider>
  );
}
