import { ClassRoomSlotCardsProp } from "./ClassRoomSlotCardsProp";
import { ProgressCheckSlotCard } from "./ProgressCheckSlotCard";
import { SpeakingClassSlotCard } from "./SpeakingClassSlotCard";
import { ClassRoomType } from "../gql/graphql";

export function ClassRoomSlotCard({ slot }: ClassRoomSlotCardsProp) {
  if (slot.type === ClassRoomType.Exam) {
    return <ProgressCheckSlotCard slot={slot} />;
  } else {
    return <SpeakingClassSlotCard slot={slot} />;
  }
}
