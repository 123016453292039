import { CalendarItemMap } from "@normasteaching/norma-ui";
import { SlotDayAndHourKey } from "./SlotDayAndHourKey";
import dayjs from "dayjs";
import {
  CalendarSlotType,
  CalendarSlotWithType,
} from "./calendarSlotWithTypes";

function resolveStartDate(slot: CalendarSlotWithType): string {
  switch (slot.type) {
    case CalendarSlotType.Availability:
      return slot.slot.startDateTime;
    case CalendarSlotType.ClassRoom:
      return slot.slot.startedAtUtc;
    default:
      throw new Error("Slot type not specified");
  }
}

export function convertSlotToCalendar(
  slots: CalendarSlotWithType[],
): CalendarItemMap<CalendarSlotWithType> {
  const map = new CalendarItemMap<CalendarSlotWithType>();

  slots.forEach((slot) => {
    const startDate = resolveStartDate(slot);

    const key = SlotDayAndHourKey.fromDate(dayjs(startDate));
    map.set(key, slot);
  });

  return map;
}
