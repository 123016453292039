import { Interval } from "@normasteaching/norma-ui";
import { getFragmentData, graphql } from "../gql";
import { useQuery } from "@apollo/client";
import { ClassroomV2GraphqlFragment } from "./ClassroomV2GraphqlFragment";

export const GetClassroomByTeacherIdAndDateRange = graphql(`
  query getClassRoomsByTeacherAndDateRange(
    $endUtc: String!
    $startUtc: String!
  ) {
    getClassRoomsByTeacherAndDateRange(endUtc: $endUtc, startUtc: $startUtc) {
      ...ClassRoomV2
    }
  }
`);

export function useClassRoomByTeacherIdAndDateRange({
  interval,
}: {
  interval: Interval;
}) {
  const { data, loading } = useQuery(GetClassroomByTeacherIdAndDateRange, {
    variables: {
      startUtc: interval.fromDate.toISOString(),
      endUtc: interval.toDate.toISOString(),
    },
    fetchPolicy: "cache-and-network",
  });
  return {
    classroom:
      getFragmentData(
        ClassroomV2GraphqlFragment,
        data?.getClassRoomsByTeacherAndDateRange,
      ) ?? [],
    loading,
  };
}
