export const dateFormatter = (d: string): string => {
  return new Date(d).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

export const getInitials = (
  teacher: string | undefined,
): string | undefined => {
  const parts = teacher?.split(" ");
  const initials = parts?.map((part) => part[0].toUpperCase());
  return initials?.join("");
};
