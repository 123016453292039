import { AvailabilitySlotCard } from "./AvailabilitySlotCard";
import {
  CalendarSlotType,
  CalendarSlotWithType,
} from "./calendarSlotWithTypes";
import { ClassRoomSlotCard } from "./ClassRoomSlotCard";

type Props = {
  slot: CalendarSlotWithType;
};

export function SlotCards({ slot }: Props) {
  if (slot.type === CalendarSlotType.Availability) {
    return <AvailabilitySlotCard slot={slot.slot} />;
  } else if (slot.type === CalendarSlotType.ClassRoom) {
    return <ClassRoomSlotCard slot={slot.slot} />;
  } else {
    return null;
  }
}
