import { useState } from "react";
import { WeeklyCalendar } from "../WeeklyCalendar";
import { Interval } from "@normasteaching/norma-ui";
import { createTodayInterval } from "../createTodayInterval";
import { EmptyCalendarMessage } from "../EmptyCalendarMessage";
import { useAvailabilityBetweenDates } from "../useAvailabilityBetweenDates";
import { SlotCards } from "../SlotCards";
import { useClassRoomByTeacherIdAndDateRange } from "../useClassRoomByTeacherIdAndDateRage";
import { convertSlotToCalendar } from "../convertSlotToCalendar";
import { NavBarWithLoggedUser } from "../../UI/Layout/NavBarWithLoggedUser";
import {
  CalendarSlotType,
  CalendarSlotWithType,
} from "../calendarSlotWithTypes";

export function FranchiseeCalendarAvailabilityScreen() {
  const [interval, setInterval] = useState<Interval>(createTodayInterval());

  const { availability, loading: availabilityLoading } =
    useAvailabilityBetweenDates({ interval });

  const { classroom, loading: classroomSlotLoading } =
    useClassRoomByTeacherIdAndDateRange({ interval });

  const isOnLoading = availabilityLoading || classroomSlotLoading;

  const combinedSlots: CalendarSlotWithType[] = [
    ...availability.map((a) => ({
      slot: a,
      type: CalendarSlotType.Availability as CalendarSlotType.Availability,
    })),
    ...classroom.map((c) => ({
      slot: c,
      type: CalendarSlotType.ClassRoom as CalendarSlotType.ClassRoom,
    })),
  ];

  return (
    <>
      <div className="bg-[#F7FAFC]">
        <NavBarWithLoggedUser />
        <div className="md:pt-20 pt-[5.4rem] px-8 overflow-visible">
          <WeeklyCalendar
            canGoToPrevWeek={true}
            emptyDayComponent={<EmptyCalendarMessage message={"Empty Day"} />}
            interval={interval}
            onChangeInterval={setInterval}
            loading={isOnLoading}
            items={convertSlotToCalendar(combinedSlots)}
            itemRenderer={(slot) => <SlotCards slot={slot} />}
          />
        </div>
      </div>
    </>
  );
}
