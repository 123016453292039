import {
  ClassRoomV2Fragment,
  TeacherAvailabilitySlotFragment,
} from "../gql/graphql";

export enum CalendarSlotType {
  Availability = "Availability",
  ClassRoom = "ClassRoom",
}

export type CalendarSlotWithType =
  | {
      type: CalendarSlotType.Availability;
      slot: TeacherAvailabilitySlotFragment;
    }
  | {
      type: CalendarSlotType.ClassRoom;
      slot: ClassRoomV2Fragment;
    };
